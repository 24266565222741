<template>
    <div class="card-container" :style="{width:(width-20)+'px'}" @click="goToGoods">
        <div class="goods-cover" :style="{width:(width-20)+'px',height:(width-20)+'px'}">
            <img :src="goods.cover">
            <div class="tag" v-if="goods.tag">{{goods.tag}}</div>
            <div class="goods-position" v-if="goods.village_name">
                <div class="goods-position-real">
                    <img src="https://api.mengjingloulan.com/storage/wechat/services/location.png"/>
                    {{goods.village_name}}
                </div>
            </div>
        </div>
        <div class="goods-title">
            {{goods.title}}
        </div>
        <div class="goods-warning-text">
            可用人生券抵扣{{goods.deduct}}元
        </div>
        <div class="goods-price">
            <div class="market-price">
                <div class="rmb">¥</div>
                <div class="text">{{goods.market_price}}</div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'goods-card',
    props: {
      width: {
        type: [String, Number],
        default: 158
      },
      goods: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    methods: {
      goToGoods () {
        this.$emit('goToGoods', this.$props.goods)
      }
    }
  }
</script>

<style scoped lang="scss">
    .card-container {
        padding: 10px;
        box-shadow: 0px 1px 7px 2px rgba(115, 115, 115, 0.05);
        border-radius: 6px;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        background: #fff;
        display: flex;
        flex-wrap: wrap;

        .goods-cover {
            width: 100%;
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            border-radius: 6px;
            overflow: hidden;
            position: relative;

            img {
                width: 100%;
                height: 100%;
            }

            .tag {
                position: absolute;
                left: 10px;
                top: 10px;
                background: linear-gradient(139deg, #FFB966 0%, #F46A17 100%);
                border-radius: 16px;
                height: 16px;
                padding: 0 5px;
                font-size: 10px;
                color: #fff;
                font-family: PingFangSC-Regular, PingFang SC;
                align-items: center;
                max-width: 80px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
                word-break: break-all;
                margin-right: 3px;
                line-height: 16px;

            }

            .goods-position {
                position: absolute;
                right: 10px;
                bottom: 10px;
                height: 16px;
                -webkit-border-radius: 4px;
                -moz-border-radius: 4px;
                border-radius: 4px;
                overflow: hidden;

                .goods-position-real {
                    background: rgba(0, 0, 0, 0.6);
                    height: 16px;
                    line-height: 16px;
                    padding: 0 3px;
                    align-items: center;
                    color: #fff;
                    font-size: 10px;
                    display: flex;
                    max-width: 100px;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                    word-break: break-all;
                    white-space: nowrap;
                    text-overflow: ellipsis;


                    img {
                        width: 14px;
                        height: 14px;
                    }
                }
            }
        }

        .goods-title {
            width: 100%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            word-break: break-all;
            font-size: 14px;
            color: #333333;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            margin-top: 8px;
        }

        .goods-warning-text {
            color: #F46A17;
            font-size: 12px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            word-break: break-all;
            font-family: PingFangSC-Semibold, PingFang SC;
            margin-top: 10px;
        }

        .goods-price {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            height: 17px;
            margin-top: 10px;

            .market-price {
                flex: 1;
                color: #C10000;
                font-size: 14px;
                justify-content: flex-end;
                font-weight: bold;
                display: flex;
                height: 17px;

                .rmb {
                    font-size: 10px;
                    height: 17px;
                    line-height: 17px;
                }

            }
        }

    }
</style>