<template>
    <rsck-page>
        <div style=" padding: 10px 15px 0 15px;">
            <searchV1
                    class="search"
                    jumpUrl="search"
                    v-model="keyword"
                    @search="init"
            ></searchV1>
        </div>
        <rsck-refresh v-if="showResult" id="refresh" ref="refresh" @onPullDown="onPullDown" @onPullUp="onPullUp"
                      :height="scrollHeight" :specialStyles="{top:(searchHeight+10)+'px'}">
            <div class="goods-list">
                <goods-card
                        v-for="(item,index) in list"
                        :key="index"
                        class="goods"
                        :goods="item"
                        @goToGoods="goToGoods"
                        :width="goodsWidth"
                />
            </div>
        </rsck-refresh>
    </rsck-page>
</template>
<script>
  import { mapActions } from 'vuex'
  import searchV1 from '@T/community/search/searchV1.vue'
  import goodsV1 from '@T/community/goods/goodsV1.vue'
  import gridV1 from '@/uicomponent/grid/gridV1.vue'
  import GoodsCard from '@/template/services/goods-card'
  import RsckPage from '@/components/common/rsck-page'
  import RsckRefresh from '@/components/common/rsck-refresh'

  export default {
    components: {
      RsckRefresh,
      RsckPage,
      searchV1,
      goodsV1,
      gridV1,
      GoodsCard
    },
    data () {
      return {
        keyword: '',
        params: {
          page: 1,
          page_size: 10,
        },
        list: [],
        goodsWidth: 0,
        listTypeId: 1,
        hasMore: false,
        isLoading: false,
        showResult: false,
        scrollHeight: 0,
        searchHeight: 0
      }
    },
    created () {
      document.title = '搜索结果'
      if (this.$route.query) {
        this.keyword = this.$route.query?.keyword || ''
      }
      this.params.page = 1
      this.list = []
      this.goodsWidth = (window.innerWidth - 40) / 2
      this.listTypeId = this.$route.query?.listTypeId || 1

      setTimeout(() => {
        this.searchHeight = document.querySelector('.search').clientHeight
        this.scrollHeight = window.innerHeight - this.searchHeight - 40
        this.init()
      }, 200)
    },
    methods: {
      ...mapActions('community_search', ['addHistory']),
      onPullDown () {
        this.init()
        this.$refs.refresh.endPullDown()
      },
      onPullUp () {

        this.getGoodsList()
        this.$refs.refresh.endPullUp(!this.hasMore)
      },
      init () {
        this.params.page = 1
        this.list = []
        this.hasMore = true
        this.getGoodsList()
      },
      async getGoodsList () {
        let keyword = this.$utils.string.trim(String(this.keyword))
        this.addHistory(keyword)
        if (!this.isLoading) {
          this.isLoading = true

          try {
            let parmas = {
              ...this.params,
              keyword: keyword,
              list_type_id: this.listTypeId
            }
            let query = await this.$api.services.goodsList(parmas)
            if (query.data.length == 0) {
              this.isLoading = false
              this.showResult = true
              return
            }

            this.params.page++

            query.data.forEach(item => {
              item.cover = item?.cover_path || ''
              item.tag = item?.class_name || ''
              item.deduct = item?.deduct_renshengquan || 0
              item.market_price = item?.price || 0
            })

            if (query.data.length < this.params.page_size) {
              this.list = [...this.list, ...query.data]
              this.isLoading = false
              this.showResult = true
              return
            }
            this.list = [...this.list, ...query.data]

            this.isLoading = false
            this.showResult = true
          } catch (error) {
            //
            this.isLoading = false
            this.showResult = true
          }
        }

      },
      goToGoods (e) {
        let goodsId = e.id
        let query = {
          id: goodsId,
          listTypeId: this.listTypeId,
        }
        query = encodeURIComponent(JSON.stringify(query))
        this.$platform.wxsdk.wxRoute({
          type: 'navigateTo',
          url: '/web/services/goods?query=' + query,
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
    #searchList {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        padding: 15px 15px;
        background: #f6f6f6;
        display: flex;
        flex-direction: column;
    }

    .search {
        width: 345px;
        height: 32px;
    }

    .goods-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .goods {
            margin-bottom: 10px;
        }
    }
</style>
